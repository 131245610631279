<template>
<div class="withdrawList page">
  <headers title="提现"></headers>
  <div class="scroll">
      <ul class="list">
        <li v-for="(item,index) in list" :key="index">
          <div class="top flexSpaceBetween">
              <p class="bankname">{{item.bankname}}</p>
              <p class="price">+ {{item.price}}</p>
          </div>
          <div class="bottom flexSpaceBetween">
            <p class="addtime">{{item.addtime}}</p>
            <p class="status">{{ getStateText(item.status)}}</p>
          </div>
        </li>
      </ul>
  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue";

export default {
  name: "withdrawList",
  components: {headers},
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(){
      $api.WithdrawIndex({
        token: this.$store.getters['getToken']
      }).then(res => {
          this.list = res.data
      })
    },
    getStateText(status){
      switch (Number(status)){
        // 1.待处理，2.已退回，3.已完成
        case 1:
          return "待处理"
        case 2:
          return "已退回"
        case 3:
          return "已完成"
      }
    }
  }
}
</script>

<style scoped lang="scss">
.withdrawList {
  .list {
    padding: 0 16px;
    li {
      padding: 15px 0;
      font-size: 13px;
      border-bottom: 1px solid #f0f0f0;
      .bottom {
        margin-top: 12px;
      }
      .bankname {
        font-size: 16px;
      }
      .price {
        font-size: 18px;
        font-weight: bold;
      }
      .addtime {
        color: #999;
      }
      .status {
        color: #ff4949;
      }
    }
  }
}
</style>